import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'
import Boilerplate from '../components/boilerplate'

const IndexPage = () => (
  <Layout>
    <SEO title="Impressum" keywords={['creopharm', 'pharma', 'co-packing']} />

    <Hero />

    <div className="container medium-padding">
      <h2>Impressum</h2>
      <p>
        <strong>Creopharm GmbH</strong>
        <br />
        Bielefelder Str. 21 a<br />
        49124 Georgsmarienhütte
        <br />
        Telefon: +49 (0) 5401 8814 – 0<br />
        Telefax: +49 (0) 5401 8814 – 100
        <br />
        E-Mail: info@creopharm.de
        <br />
        Website: <a href="http://www.creopharm.de/">http://www.creopharm.de</a>
      </p>

      <p>
        Registergericht: Amtsgericht Osnabrück
        <br />
        Handelsregisternummer: HRB 212868
        <br />
        Geschäftsführender Gesellschafter: Klaus Gustenberg
        <br />
        Finanzamt Osnabrück-Stadt
      </p>

      <p>
        <strong>Rechtshinweis:</strong>
        <br />
        Alle in diesem Internetangebot enthaltenen Informationen und Angaben
        wurden sorgfältig recherchiert und geprüft. Diese Informationen sind ein
        Service und ersetzen eine persönliche Beratung nicht. Weder der
        Betreiber dieses Internetangebots noch dritte Lieferanten können für
        Richtigkeit, Vollständigkeit und Aktualität die Haftung übernehmen.
      </p>

      <p>
        Alle Informationen dienen ausschließlich zur Information der Besucher
        des Onlineangebotes. Im Übrigen ist die Haftung auf Vorsatz und grobe
        Fahrlässigkeit beschränkt. Für Internetseiten Dritter, auf die durch
        Hyperlink verweist, tragen die jeweiligen Anbieter die Verantwortung.
        Der Betreiber dieses Internetangebots ist für den Inhalt solcher Seiten
        Dritter nicht verantwortlich. Wir möchten ausdrücklich betonen, dass wir
        keinerlei Einfluss auf Gestaltung und Inhalte der gelinkten Seiten
        haben. Deshalb distanzieren wir uns hiermit ausdrücklich von allen
        Inhalten aller gelinkten Seiten auf unserer Website und machen uns ihre
        Inhalte nicht zu Eigen.
      </p>

      <p>
        Diese Erklärung gilt für alle auf unserer Website angebrachten Links und
        für alle Inhalte der Seiten, zu denen die bei uns aufgeführten Banner
        und Grafiken führen. Des Weiteren kann diese Website ohne Wissen des
        Betreibers von einer anderen Seite verlinkt worden sein. Der Betreiber
        übernimmt keine Verantwortung für Inhalt, Darstellung oder irgendeiner
        Verbindung zu Websites Dritter.
      </p>

      <p>
        Außerdem behält sich der Betreiber das Recht vor, Änderungen oder
        Ergänzungen der bereitgestellten Informationen vorzunehmen. Aus den in
        dieser Dokumentation beschriebenen Themenbereichen können keine
        Rechtsansprüche abgeleitet werden. Inhalt und Struktur dieses
        Onlineangebotes sind urheberrechtlich geschützt. Die Vervielfältigung
        von Inhalten oder Daten, insbesondere die Verwendung von Texten,
        Textteilen oder Bildmaterial bedarf der ausdrücklichen vorherigen
        Zustimmung des Betreibers sowie seiner Partner. Die Inhalte dürfen weder
        ganz noch teilweise ohne vorherige schriftliche Genehmigung des Urhebers
        vervielfältigt und/oder veröffentlicht oder in einem Informationssystem
        gespeichert werden.
      </p>

      <p>
        Alle personenbezogenen Daten werden grundsätzlich vertraulich behandelt.
        Bei der Erhebung, Verarbeitung und Nutzung der Daten und Einzelangaben
        halten wir uns an die gesetzlichen Bestimmungen des
        Bundesdatenschutzgesetzes und des Telemediengesetzes. Sie können
        jederzeit schriftlich der Nutzung, Verarbeitung bzw. der Übermittlung
        ihrer Daten auch zu Marketingzwecken durch schriftliche Mitteilung an
        obige Adresse widersprechen bzw. Ihre Einwilligung widerrufen. Für
        weitere Informationen, siehe Datenschutz.
      </p>
    </div>

    <Boilerplate lang="de" />
  </Layout>
)

export default IndexPage
